import axios from "axios";
import { getToken } from "@/utils";
import { Toast } from "./ui";

Toast.allowMultiple();

const instance = axios.create({
  // baseURL: "",
  // baseURL: process.env.VUE_APP_BASE_API
  // timeout: 1000,
  // headers: { "X-Custom-Header": "foobar" }
});

let _loading;
let count = 0;

instance.interceptors.request.use(
  function(config) {
    // Do something before request is sent

    const token = getToken();
    config.headers["Authorization"] = `Bearer ${token}`;

    count += 1;
    if (count === 1) {
      _loading = Toast.loading({
        duration: 0,
        // forbidClick: true,
        message: "加载中..."
      });
    }

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // return response;

    count -= 1;
    if (count === 0) {
      _loading && _loading.clear();
    }

    let isError = false;
    let msg = "";
    if (response.status !== 200) {
      isError = true;
    }
    if ((response.data || {}).code !== 200) {
      isError = true;
      msg = (response.data || {}).msg;
    }

    if (isError) {
      Toast.fail(msg || "服务异常");
    }

    return response.data;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    count -= 1;
    if (count === 0) {
      _loading && _loading.clear();
    }

    setTimeout(() => {
      Toast.fail("网络异常"); // TODO: 待优化
    }, 0);
    return Promise.reject(error);
  }
);

export default instance;
