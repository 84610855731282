import request from "@/utils/request";
// import qs from "qs";

// 接口文档: https://yapi.int.ybm100.com/project/1667/interface/api

// 我推广的订单查询
export function selectOrderByReferee(data) {
  return request({
    url: `/api/ordmc/ordShopPolicy/selectOrderByReferee`,
    method: "get",
    params: data
  });
}

// 获取经纪人钱包信息
export function selectRefereeWallet() {
  return request({
    url: `/api/usermc/userReferee/selectRefereeWallet`,
    method: "get"
  });
}

// 查询银行卡
export function selectBank() {
  return request({
    url: `/api/usermc/userBank/selectBank`,
    method: "get"
  });
}

// 绑定银行卡
export function bindingBank(data) {
  return request({
    url: `/api/usermc/userBank/bindingBank`,
    method: "post",
    data: data
  });
}

// 修改银行卡接口
export function updateBank(data) {
  return request({
    url: `/api/usermc/userBank/updateBank`,
    method: "post",
    data: data
  });
}

// 图片上传
export function uploadImage(data) {
  return request({
    url: `/api/zuul/admin/file/uploadImage`,
    method: "post",
    configs: {
      headers: { "Content-Type": "multipart/form-data" }
    },
    data: data
  });
}

// 图片上传 最终转换为jpg格式
export function uploadImageByJpg(data) {
  return request({
    url: `/api/zuul/admin/file/uploadImageByJpg`,
    method: "post",
    configs: {
      headers: { "Content-Type": "multipart/form-data" }
    },
    data: data
  });
}

// 根据邀请人id查询邀请人信息
export function selectRefereeInfoByRefereeId(data) {
  return request({
    url: `/api/usermc/userReferee/selectRefereeInfoByRefereeId`,
    method: "get",
    params: data
  });
}

/***
 * 入参
 ** 民族：family_name
 ** 开户行：
 ** 学历：educational_degree
 ** 政治面貌：policital_status
 * **/
// 数字字典
export function selectDictItemByTypes(data) {
  return request({
    url: `/api/admin/dict/selectDictItemByTypes`,
    method: "post",
    data
  });
}

// 获取省市
export function getAllArea(data) {
  return request({
    url: `/api/admin/sysAreaT/getAllAreaNew`,
    method: "post",
    data
  });
}

// 发送验证码
export function sendSms(data) {
  return request({
    url: `/api/usermc/loginOrReg/sendSms`,
    method: "post",
    params: data
  });
}

// 验证码验证
export function checkValidCode(data) {
  return request({
    url: `/api/usermc/loginOrReg/checkValidCode`,
    method: "post",
    params: data
  });
}

// 重新审批信息回显
export function selectMemberInfo() {
  return request({
    url: `/api/usermc/userReferee/selectMemberInfo`,
    method: "get"
  });
}

// 经纪人申请提交接口
export function commitRefereeInfo(data) {
  return request({
    url: `/api/usermc/userReferee/commitRefereeInfo`,
    method: "post",
    data
  });
}

// 经纪人申请状态
export function queryAuditState() {
  return request({
    url: `/api/usermc/userReferee/queryAuditState`,
    method: "get"
  });
}

// 身份证真实性校验
export function checkIdCard(data) {
  return request({
    url: `/api/usermc/userReferee/checkIdCard`,
    method: "post",
    data
  });
}
